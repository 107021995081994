<template>
  <div @click="edit()" class="input-group">
    <label class="label">{{ $t(`id-check-confirmation.data.${inputKey}`) }}</label>
    <input
      type="text"
      class="input"
      :class="{
        active: keyboard.inputKey == inputKey,
        input: true,
        'small-input': smallInput,
      }"
      :value="keyboard.inputKey == inputKey ? keyboard.value : value"
      @click="edit()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DataFieldInput",
  props: {
    value: String,
    inputKey: String,
  },
  computed: {
    ...mapGetters(["keyboard"]),
    smallInput() {
      return this.inputKey === "house_number" ? "small-input" : "";
    },
  },
  methods: {
    edit() {
      const isKeyboardActive = this.$store.getters.keyboard.show;
      if (isKeyboardActive) return;
      if (
        this.inputKey === "zip_code" ||
        this.inputKey === "city" ||
        this.inputKey === "street" ||
        this.inputKey === "house_number"
      ) {
        this.$store.commit("SET_KEYBOARD_INPUT_KEY", this.inputKey);
        this.$store.commit("SET_KEYBOARD_VALUE", this.value);
        this.$store.commit("SET_KEYBOARD", true);
      }
    },
  },
};
</script>

<style scoped>
.input-group {
  display: flex;
  flex-direction: column;
  margin: 0.5vw;
}
.input-group .active {
  border: 0.15vw solid #68aef9;
}
.input-group label {
  font-family: "Roboto Bold";
  color: #585858;
  font-size: 0.8vw;
  margin-bottom: 0.25vw;
}
.input-group input {
  width: 13vw;
  height: 2.25vw;
  text-align: center;
  background: #908b88;
  border-radius: 0.5vw;
  border: none;
  color: #fff;
  font-size: 1.25vw;
  font-family: "Roboto Bold";
  pointer-events: none;
}
.grid-keyboard .input-group input {
  width: 11vw;
}
.input-group input:focus {
  outline: none;
}
.small-input {
  width: 7.5vw !important;
}
</style>
