import { createApp } from "vue";

import store from "./store";
import router from "./router";
import i18n from "./locale";
import VueClickAway from "vue3-click-away";

import App from "./App.vue";

const app = createApp(App);

app.use(i18n);
app.use(router);
app.use(store);
app.use(VueClickAway);

app.mount("#app");
