<template>
  <div class="cancel" @click="cancel()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 29 29"
    >
      <path
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="6"
        d="M26 26 14.5 14.5m0 0L3 3m11.5 11.5L26 3M14.5 14.5 3 26"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "CancelComponent",
  props: {
    back: String,
  },
  methods: {
    cancel() {
      this.$store.commit("CLEAR_DATA");

      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.cancel {
  position: absolute;
  right: -1.5vw;
  top: 1.5vw;
  height: 3vw;
  width: 3vw;
  border-radius: 50%;
  background: rgba(217, 217, 217, 0.5);
  box-shadow: 0 5px 5px 0 rgba(68, 68, 68, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}

.cancel svg {
  transform: rotate(180deg);
  height: 1vw;
  width: 1vw;
}

.cancel:hover {
  cursor: pointer;
  transform: scale(1.1);
}
</style>
