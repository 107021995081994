export const getters = {
  idCheckStatus: (state) => state.idCheckStatus,
  sms_code_timeout: (state) => state.data.sms_code_timeout,
  email_code_timeout: (state) => state.data.email_code_timeout,
  keyboard: (state) => state.keyboard,
  isPartnerSelected: (state) => (name) => {
    return state.data.selectedPartners[name];
  },
  getSelectedPartners: (state) => {
    return Object.keys(state.data.selectedPartners).filter(
      (key) => state.data.selectedPartners[key]
    );
  },
  get_auth_page: (state) => state.data.authPage,
  gender: (state) => state.data.gender,
  phone: (state) => state.data.phone,
  phone_dialCode: (state) => state.data.phoneDialCode,
  sms_code: (state) => state.data.sms_code,
  email_code: (state) => state.data.email_code,
  countries: (state) => state.countries,
  photo_image: (state) => state.data.images.photo,
  scan_images: (state) => state.data.images.scan,
  partners: (state) => state.partners,
  idCheckData: (state) => state.idCheckData,
  addressCheckData: (state) => state.addressCheckData,
  jobs: (state) => state.jobs,
  currentJob: (state) => state.data.currentJob,
  email: (state) => state.data.email,
  templateMails: (state) => state.templateMails,
};
