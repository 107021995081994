<template>
  <FaceLayout back="/id-check">
    <iframe
      v-show="!done"
      :src="Iframe"
      frameborder="0"
      allowtransparency="true"
      class="iframe"
      width="100%"
      height="100%"
      allow="camera"
      ref="iframe"
      lazy
    ></iframe>
  </FaceLayout>
</template>

<script>
import FaceLayout from "./FaceLayout.vue";

export default {
  components: {
    FaceLayout,
  },
  name: "FaceRecognition",
  data() {
    return {
      done: false,
      interval: null,
      messageListener: null,
    };
  },
  computed: {
    Iframe() {
      return process.env.VUE_APP_LIVENESS_URL;
    },
  },
  methods: {
    generateLanguage() {
      const awsLocales = this.$i18n.t("face_check.aws_locales")
        ? Object.keys(this.$i18n.messages[this.$i18n.locale].face_check.aws_locales || {})
        : [];

      const lang = {};

      awsLocales.forEach((locale) => {
        lang[locale] = this.$i18n.t(`face_check.aws_locales.${locale}`);
      });

      return lang;
    },
    init() {
      const iframe = this.$refs.iframe;
      const checkPostMessage = () => {
        if (!iframe.contentWindow || !iframe.contentWindow.postMessage) {
          console.warn("Iframe contentWindow or postMessage not ready");
          setTimeout(checkPostMessage, 100); // Retry after 100ms
          return;
        }

        const language = this.generateLanguage();

        iframe.contentWindow.postMessage(
          {
            type: "prism-ident",
            data: {
              lang: language,
            },
          },
          "*"
        );

        this.interval = setInterval(() => {
          if (!iframe.contentWindow || !iframe.contentWindow.postMessage) {
            clearInterval(this.interval);
            return;
          }

          iframe.contentWindow.postMessage(
            {
              type: "prism-ident",
              data: {
                message: "heartbeat",
              },
            },
            "*"
          );
        }, 1000);
      };

      iframe.onload = () => {
        checkPostMessage();
      };

      this.messageListener = (event) => {
        if (event.origin !== process.env.VUE_APP_LIVENESS_URL) {
          return;
        }

        if (event.data.type != "liveness") {
          return;
        }

        this.handleReponse(JSON.parse(event.data.data));
      };

      window.addEventListener("message", this.messageListener);
    },
    async handleReponse(data) {
      if (!data || !data?.Status) {
        return;
      }
      console.log("handleReponse", data);

      this.done = true;
      this.$refs.iframe.src = "";

      if (data.Status != "SUCCEEDED") {
        this.$router.push("/id-check/face/failed");
        return;
      }

      if (data.Confidence < process.env.VUE_APP_LIVENESS_SCORE) {
        this.$router.push("/id-check/face/failed");
        return;
      }

      const success = await this.$store.dispatch("compareFaces", {
        compareImage: data.ReferenceImage,
      });

      if (!success) {
        this.$router.push("/id-check/face/failed");
        return;
      }

      setTimeout(() => {
        this.$router.push("/id-check/face/success");
      }, 500);
    },
  },
  mounted() {
    this.init();
  },
  beforeUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
      console.log("clearInterval");
    }
  },
  unmounted() {
    if (this.messageListener) {
      window.removeEventListener("message", this.messageListener);
      this.messageListener = null;
    }
  },
};
</script>

<style scoped>
.iframe {
  height: 60vh;
  margin-top: -5vh;
  transform: scale(0.95);
}
</style>
