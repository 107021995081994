<template>
  <div>
    <header-component></header-component>

    <div class="content">
      <div class="area">
        <div class="title">{{ $t("register.done") }}</div>
        <div class="circle-img">
          <img src="@/assets/img/partner/user-tick.svg" alt="circle" />
        </div>
        <div class="description">
          {{ $t("register.description") }}
        </div>
      </div>
    </div>
    <language-component></language-component>
  </div>
</template>

<script>
import LanguageComponent from "../components/LanguageComponent.vue";
import HeaderComponent from "../components/HeaderComponent.vue";

export default {
  components: { LanguageComponent, HeaderComponent },
  name: "RegisterDonePage",
  data() {},
  computed: {},
  methods: {},
  mounted() {
    this.$store.dispatch("register");

    setTimeout(() => {
      this.$router.push("/");
    }, 10000);
  },
};
</script>

<style scoped>
.content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.area {
  border-radius: 3vw;
  height: 25vw;
  width: 40vw;
  background: rgba(217, 217, 217, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title {
  color: #fff;
  font-size: 2vw;
  font-family: "Roboto Bold";
}

.circle-img {
  border-radius: 50%;
  background: #68aef9;
  width: 10vw;
  height: 10vw;
  box-shadow: 0 5px 5px 0 #44444425;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2vw;
  margin-bottom: 2vw;
}
.circle-img img {
  width: 6vw;
  height: 6vw;
}
.description {
  color: #fff;
  font-size: 1.5vw;
  font-family: "Roboto Bold";
  text-align: center;
}
</style>
