<template>
  <div>
    <header-component :msg="$t('email.email_verify_enter')"></header-component>
    <div class="content">
      <div class="code-verify-input">
        <div class="code-inputs">
          <CodeInput v-for="(digit, index) in code" :key="index" :value="code[index]" />

          <button class="btn" v-if="error && !email_code_timeout" @click="resendCode()">
            {{ $t("email.resend_code") }}
          </button>
        </div>

        <div class="alert bg-error" v-if="error">
          <span>{{ $t("email.code_invalid") }}</span>
        </div>

        <div class="alert bg-success" v-if="success">
          <span>{{ $t("email.code_valid") }}</span>
        </div>

        <numpad-component :input="currentInput" @input="handleNumpadClick" />
      </div>
    </div>
    <language-component back="/email"></language-component>
  </div>
</template>

<script>
import CodeInput from "../../components/input/CodeInput.vue";
import NumpadComponent from "../../components/keyboard/NumpadComponent.vue";
import LanguageComponent from "../../components/LanguageComponent.vue";
import HeaderComponent from "../../components/HeaderComponent.vue";
import { mapGetters } from "vuex";

export default {
  name: "VerifyEmailPage",
  components: { LanguageComponent, HeaderComponent, CodeInput, NumpadComponent },
  data() {
    return {
      error: false,
      success: false,
      code: ["", "", "", ""],
      currentIndex: 0,
      timeout: 0,
    };
  },
  computed: {
    ...mapGetters(["email_code_timeout"]),
    currentInput() {
      return this.code.join("");
    },
  },
  mounted() {
    this.$store.dispatch("sendEmailCode");
  },
  methods: {
    handleNumpadClick(inputValue) {
      console.log(this.currentIndex);

      if (this.error) {
        this.error = false;
      }
      if (inputValue === "") {
        this.clearCode();
        return;
      }

      if (inputValue.length < this.currentInput.length) {
        this.removeLastDigit();
        return;
      }

      if (this.currentIndex < 4) {
        const newDigit = inputValue[this.currentInput.length];
        this.code[this.currentIndex] = newDigit;
        this.currentIndex++;
      }

      if (this.currentIndex === 4) {
        setTimeout(() => {
          this.verifyCode();
        }, 100);
      }
    },
    async verifyCode() {
      const isValid = await this.$store.dispatch("checkEmailCode", this.code);

      if (isValid) {
        this.success = true;

        setTimeout(() => {
          this.$router.push("/id-check");
        }, 1000);
      } else {
        this.error = true;
        this.clearCode();
      }
    },
    resendCode() {
      if (this.email_code_timeout) {
        return;
      }

      this.$store.dispatch("sendEmailCode");

      this.$store.commit("SET_EMAIL_CODE_TIMEOUT", true);

      this.$store.dispatch("startEmailCodeTimeout", true);

      this.clearCode();
    },
    clearCode() {
      this.code = ["", "", "", ""];
      this.currentIndex = 0;
    },
    removeLastDigit() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.code[this.currentIndex] = "";
      }
    },
  },
};
</script>

<style scoped>
.button {
  display: flex;
}
.btn {
  background-color: #68aef9;
  color: #fff;
  font-family: "Roboto Bold";
  font-size: 1.35vw;
  letter-spacing: -0.075vw;
  padding: 0.5vw 1vw;
  border-radius: 1.5vw;
  margin-left: 1vw;
  width: 15vw;
  height: 4.5vw;
  border: none;
  position: absolute;
  right: 3vw;
  cursor: pointer;
  transition: transform 0.2s;
}
.btn:hover {
  transform: scale(1.05);
}
.btn:focus {
  outline: none;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.code-verify-input {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.code-inputs {
  display: flex;
  margin-bottom: 1vh;
}
.alert {
  width: 14vw;
  color: #fff;
  text-align: center;
  font-family: "Roboto Bold";
  font-size: 1vw;
  letter-spacing: -0.075vw;
  padding: 0.5vw;
  border-radius: 1.5vw;
  margin-bottom: 1vw;
}
.bg-error {
  background-color: #ff4a4a;
}
.bg-success {
  background-color: #3bde82;
}
</style>
