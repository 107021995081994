<template>
  <div>
    <header-component :msg="$t('jobs.title')"></header-component>
    <div class="content">
      <div v-for="job in jobs" :key="job.name">
        <div
          class="work"
          @click="setJob(job)"
          :class="['work-box', { 'work-box-active': job.name == currentJob.name }]"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.0002 22.7501C11.3302 22.7501 10.6502 22.5801 10.0502 22.2301L4.11016 18.8001C2.91016 18.1001 2.16016 16.8101 2.16016 15.4201V8.58011C2.16016 7.19011 2.91016 5.90011 4.11016 5.20011L10.0502 1.77012C11.2502 1.07012 12.7402 1.07012 13.9502 1.77012L19.8902 5.20011C21.0902 5.90011 21.8402 7.19011 21.8402 8.58011V15.4201C21.8402 16.8101 21.0902 18.1001 19.8902 18.8001L13.9502 22.2301C13.3502 22.5801 12.6702 22.7501 12.0002 22.7501ZM12.0002 2.7501C11.5902 2.7501 11.1702 2.8601 10.8002 3.0701L4.86016 6.5001C4.12016 6.9301 3.66016 7.72011 3.66016 8.58011V15.4201C3.66016 16.2701 4.12016 17.0701 4.86016 17.5001L10.8002 20.9301C11.5402 21.3601 12.4602 21.3601 13.2002 20.9301L19.1402 17.5001C19.8802 17.0701 20.3402 16.2801 20.3402 15.4201V8.58011C20.3402 7.73011 19.8802 6.9301 19.1402 6.5001L13.2002 3.0701C12.8302 2.8601 12.4102 2.7501 12.0002 2.7501Z"
              fill="#fff"
            />
            <path
              d="M11.9999 11.7501C10.2999 11.7501 8.91992 10.37 8.91992 8.67004C8.91992 6.97004 10.2999 5.59009 11.9999 5.59009C13.6999 5.59009 15.0799 6.97004 15.0799 8.67004C15.0799 10.37 13.6999 11.7501 11.9999 11.7501ZM11.9999 7.09009C11.1299 7.09009 10.4199 7.80004 10.4199 8.67004C10.4199 9.54004 11.1299 10.2501 11.9999 10.2501C12.8699 10.2501 13.5799 9.54004 13.5799 8.67004C13.5799 7.80004 12.8699 7.09009 11.9999 7.09009Z"
              fill="#fff"
            />
            <path
              d="M16 17.4101C15.59 17.4101 15.25 17.0701 15.25 16.6601C15.25 15.2801 13.79 14.1501 12 14.1501C10.21 14.1501 8.75 15.2801 8.75 16.6601C8.75 17.0701 8.41 17.4101 8 17.4101C7.59 17.4101 7.25 17.0701 7.25 16.6601C7.25 14.4501 9.38 12.6501 12 12.6501C14.62 12.6501 16.75 14.4501 16.75 16.6601C16.75 17.0701 16.41 17.4101 16 17.4101Z"
              fill="#fff"
            />
          </svg>
        </div>
        <p class="work-text">{{ $t("jobs." + job.name) }}</p>
      </div>
    </div>
    <progress-component level="3"></progress-component>
    <language-component ></language-component>
  </div>
</template>

<script>
import ProgressComponent from "../components/ProgressComponent.vue";
import LanguageComponent from "../components/LanguageComponent.vue";
import HeaderComponent from "../components/HeaderComponent.vue";
import { mapGetters } from "vuex";

export default {
  components: { ProgressComponent, LanguageComponent, HeaderComponent },
  name: "SelectJob",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["jobs", "currentJob"]),
  },
  methods: {
    setJob(job) {
      this.$store.commit("setCurrentJob", job);

      setTimeout(() => {
        this.$router.push("/terms-and-conditions");
      }, 300);
    },
  },
};
</script>

<style scoped>
.content {
  display: flex;
  gap: 1.5vw;
  margin-top: 7.5vw;
  justify-content: center;
}
.work-box {
  background: rgba(217, 217, 217, 0.5);
  width: 7.5vw;
  height: 7.5vw;
  border-radius: 2.5vw;
  box-shadow: 0 10px 20px 0 #53535350;
  transition: transform 0.3s ease-in-out;
  display: flex;
  align-items: center;
}
.work-box:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.work svg {
  width: 100%;
  height: 5vw;
}
.work-text {
  color: #fff;
  font-family: "Roboto Bold";
  font-size: 1.25vw;
  text-align: center;
  letter-spacing: -0.075vw;
  margin-top: 2vw;
}

.work-box-active {
  background: #68aef9;
}
</style>
