<template>
  <div>
    <header-component :msg="$t('terms.title')"></header-component>
    <div class="content">
      <div class="terms">
        <div class="checkbox">
          <input
            type="checkbox"
            id="terms"
            name="terms"
            value="terms"
            v-model="isTermsChecked"
          />
          <label>
            {{ $t("terms.i_accept") }}
            <span class="link-container">
              <u @click="showIframe('agb')">{{ $t("terms.agb") }}</u>
            </span>
          </label>
        </div>
        <div class="checkbox">
          <input
            type="checkbox"
            id="conditions"
            name="terms"
            value="conditions"
            v-model="isConditionsChecked"
          />
          <label>
            {{ $t("terms.i_accept") }}
            <span class="link-container">
              <u @click="showIframe('dsgvo')">{{ $t("terms.dsgvo") }}</u>
            </span>
          </label>
        </div>
        <div class="btn" @click="submit">
          {{ $t("terms.accept") }}
        </div>
      </div>
    </div>

    <progress-component level="4"></progress-component>
    <language-component></language-component>

    <iframe-component v-if="showBox.agb" link="https://prism-ident.com/agb" @close="hideIframe"></iframe-component>
    <iframe-component v-if="showBox.dsgvo" link="https://prism-ident.com/datenschutzerklaerung" @close="hideIframe"></iframe-component>
   
  </div>
</template>

<script>
import ProgressComponent from "../components/ProgressComponent.vue";
import LanguageComponent from "../components/LanguageComponent.vue";
import IframeComponent from "../components/IframeComponent.vue";
import HeaderComponent from "../components/HeaderComponent.vue";

export default {
  components: { LanguageComponent, HeaderComponent, ProgressComponent, IframeComponent },
  name: "TermsAndConditionsPage",
  data() {
    return {
      isTermsChecked: false,
      isConditionsChecked: false,
      showBox: {
        agb: false,
        dsgvo: false,
      },
    };
  },
  computed: {
    canSubmit() {
      return this.isTermsChecked && this.isConditionsChecked;
    },
  },
  methods: {
    submit() {
      if (this.canSubmit) {
        this.$router.push("/register-where");
      }
    },
    showIframe(type) {
      if (type === "agb") {
        this.showBox.dsgvo = false;
      } else {
        this.showBox.agb = false;
      }
      this.showBox[type] = true;
    },
    hideIframe() {
      this.showBox.agb = false;
      this.showBox.dsgvo = false;
    },
  },
};
</script>

<style scoped>
.content {
  display: flex;
  justify-content: center;
  margin-top: 7.5vw;
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 3vw;
  margin-bottom: 2vw;
}

.checkbox label {
  color: #fff;
  font-family: "Roboto Bold";
  font-size: 1.25vw;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 3vw;
  width: 3vw;
  height: 3vw;
  background: rgba(217, 217, 217, 0.5);
  box-shadow: 0 5px 5px 0 rgba(68, 68, 68, 0.25);
}

input[type="checkbox"]:checked {
  background: #68aef9;
  border: 0.25vw solid #fff;
}

input[type="checkbox"]:checked::after {
  content: "";
  background-image: url("@/assets/img/checkmark.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: inline-block;
  width: 1vw;
  height: 1vw;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn {
  background-color: #68aef9;
  color: #fff;
  font-family: "Roboto Bold";
  font-size: 1.5vw;
  letter-spacing: -0.075vw;
  padding: 0.5vw 1vw;
  border-radius: 3vw;
  margin-left: 1vw;
  width: 13vw;
  height: 3vw;
  border: none;
  margin: auto;
  margin-top: 5vw;
  cursor: pointer;
  text-align: center;
  line-height: 3vw;
  transition: transform 0.2s;
  box-shadow: 0 5px 5px 0 rgba(45, 45, 45, 0.15);
}
.btn:hover {
  transform: scale(1.05);
}
.btn:focus {
  outline: none;
}

u {
  cursor: pointer;
}

.link-container {
  position: relative;
}

</style>
