import i18n from "./locale";

const parseAddress = (addressString) => {
  const notFound = i18n.global.t("id_check.not_found");
  if (!addressString)
    return {
      zip_code: notFound,
      city: notFound,
      street: notFound,
      house_number: notFound,
    };

  const address = addressString.split(",") || [notFound];
  const zipCode = address[0]?.match(/\d+/)?.[0] || notFound;
  const city = address[0]?.replace(/\d+/g, "").trim() || notFound;

  const streetAndNumber = address[1]?.split(" ") || [];
  const street = streetAndNumber.slice(0, -1).join(" ").trim() || notFound;
  const houseNumber = streetAndNumber.slice(-1)[0] || notFound;

  return { zip_code: zipCode, city, street, house_number: houseNumber };
};

const formatDate = (date) => {
  if (!date) return i18n.global.t("id_check.not_found");
  return `${date.day.toString().padStart(2, "0")}.${date.month
    .toString()
    .padStart(2, "0")}.${date.year}`;
};

const formatGender = (gender) => {
  switch (gender) {
    case "F":
      return i18n.global.t("gender.female");

    case "M":
      return i18n.global.t("gender.male");

    case "D":
      return i18n.global.t("gender.diverse");
  }

  return i18n.global.t("id_check.not_found");
};

const formatDocumentType = (type) => {
  switch (type) {
    case "P":
      return i18n.global.t("id_check.codes.P");

    case "ID":
      return i18n.global.t("id_check.codes.ID");

    case "I":
      return i18n.global.t("id_check.codes.ID");

    case "AR":
      return i18n.global.t("id_check.codes.AR");

    case "AF":
      return i18n.global.t("id_check.codes.AR");
  }

  return i18n.global.t("id_check.codes.OTHER");
};

export const validateBirthdate = (birthdate) => {
  if (!birthdate) {
    return { error: true, message: i18n.global.t("id_check.invalid_document") };
  }

  const birthDate = new Date(birthdate.year, birthdate.month - 1, birthdate.day);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();

  const hasBirthdayPassed = today.getMonth() > birthDate.getMonth() ||  (today.getMonth() === birthDate.getMonth() && today.getDate() >= birthDate.getDate());

  if (!hasBirthdayPassed) {
    age -= 1;
  }

  if (age < 18) {
    return { error: true, message: i18n.global.t("id_check.too_young") };
  }

  return { error: false, message: null };
};


export const validateExpiryDate = (expiryDate) => {
  if (!expiryDate) {
    return { error: true, message: i18n.global.t("id_check.invalid_document") };
  }

  const expiryDateObj = new Date(expiryDate.year, expiryDate.month - 1, expiryDate.day);
  const today = new Date();

  if (expiryDateObj < today) {
    return { error: true, message: i18n.global.t("id_check.document_expired") };
  }

  return { error: false, message: null };
};

// fetch VUE_APP_API_URL/settings and check if otpEnabled is true
export const getSettings = async () => {
  const response = await fetch(`${process.env.VUE_APP_API_URL}/settings`);
  const settings = await response.json();
  return settings;
};

export default {
  parseAddress,
  formatDate,
  formatGender,
  formatDocumentType,
  validateBirthdate,
  validateExpiryDate,
  getSettings,
};
