<template>
  <div :class="[{ 'area-keyboard': !keyboard.show && editable }, areaClass]">
    <div :class="[{ 'grid-keyboard': !keyboard.show && editable }, gridClass]">
      <data-field-input
        v-for="(value, key) in data"
        :key="key"
        :inputKey="key"
        :value="getValue(key, value)"
        disabled
      />
    </div>
    <SmallKeyboardComponent v-if="showSmallKeyboard"></SmallKeyboardComponent>
  </div>
</template>

<script>
import DataFieldInput from "./input/DataFieldInput.vue";
import SmallKeyboardComponent from "./keyboard/SmallKeyboardComponent.vue";
import { mapGetters } from "vuex";
import helpers from "../helpers";

export default {
  components: { DataFieldInput, SmallKeyboardComponent },
  name: "DataFieldComponent",
  props: {
    data: Object,
    editable: Boolean,
  },
  computed: {
    ...mapGetters(["keyboard"]),

    gridClass() {
      return Object.keys(this.data).length <= 4 ? "grid-container-sm" : "grid-container";
    },
    areaClass() {
      return Object.keys(this.data).length <= 4 ? "area-sm" : "area";
    },
    showSmallKeyboard() {
      return this.keyboard.show && this.editable;
    },
  },
  methods: {
    getValue(key, value) {
      let returnValue = value;

      switch (key) {
        case "document_type":
          returnValue = helpers.formatDocumentType(value);
          break;
        case "gender":
          returnValue = helpers.formatGender(value);
          break;
        case "birthplace":
          returnValue = value ? value : this.$i18n.t("id_check.not_found");
          break;
      }

      return returnValue;
    },
  },
};
</script>

<style scoped>
.area {
  width: 45vw;
  height: 100%;
  background-color: rgba(217, 217, 217, 0.5);
  border-radius: 3vw;
  overflow: hidden;
  box-shadow: 0 10px 20px 0 rgba(83, 83, 83, 0.25);
}
.grid-container {
  display: flex;
  flex-wrap: wrap;
  height: 90%;
  padding: 1vw;
}
.area-sm {
  height: 100%;
  background-color: rgba(217, 217, 217, 0.5);
  border-radius: 1.5vw;
  overflow: hidden;
  box-shadow: 0 10px 20px 0 rgba(83, 83, 83, 0.25);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.grid-container-sm {
  display: flex;
  justify-content: center;
  height: 90%;
  padding: 1vw;
}
.area-keyboard {
  margin-top: 7.5vw;
}
</style>
