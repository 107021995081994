<template>
  <div>
    <header-component :msg="$t('register.title')"></header-component>
    <div class="content">
      <div class="partners" ref="partners">
        <div
          class="partner"
          v-for="partner in visiblePartners"
          :key="partner.id"
          @click="showTerms(partner)"
          :class="{
            'partner-selected': isPartnerSelected(partner.name),
          }"
        >
          <div
            class="partner-terms"
            v-if="partner.showTerms"
            @click.stop="selectPartner(partner)"
            v-click-away="() => (partner.showTerms = false)"
          >
            <u @click.stop="openLink(partner.agb)">{{ $t("register.terms.agb") }}</u>
            {{ $t("register.terms.and") }}
            <u @click.stop="openLink(partner.dsgvo)">{{ $t("register.terms.dsgvo") }}</u>
            {{ $t("register.terms.accept") }}
          </div>
          <div class="partner-box">
            <img :src="partner.image" :alt="partner.name" />
          </div>
        </div>
      </div>
      <div class="overflow-dots">
        <span
          v-for="(n, index) in totalDots"
          :key="n"
          class="dot"
          :style="{ backgroundColor: index === activeDot ? '#68AEF9' : '#D9D9D9' }"
          @click="scrollToPartner(index)"
        ></span>
      </div>
      <div class="btn" @click="submit()">Abschließen</div>
    </div>
    <language-component ></language-component>

    <iframe-component v-if="link" :link="link" @close="hideIframe"></iframe-component>
  </div>
</template>

<script>
import LanguageComponent from "../components/LanguageComponent.vue";
import HeaderComponent from "../components/HeaderComponent.vue";
import IframeComponent from "../components/IframeComponent.vue";
import { mapMutations, mapGetters } from "vuex";

export default {
  components: { LanguageComponent, HeaderComponent, IframeComponent },

  name: "RegisterPage",
  data() {
    return {
      currentIndex: 0,
      link: "",
    };
  },
  computed: {
    ...mapGetters(["isPartnerSelected", "getSelectedPartners"]),
    visiblePartners() {
      return this.$store.getters.partners;
    },
    totalDots() {
      return Math.ceil(this.visiblePartners.length / 8);
    },
    activeDot() {
      return this.currentIndex;
    },
  },
  methods: {
    ...mapMutations(["TOGGLE_SELECTED_PARTNER"]),
    hideIframe() {
      this.link = "";
    },
    showTerms(partner) {
      if (this.isPartnerSelected(partner.name)) {
        this.TOGGLE_SELECTED_PARTNER(partner);
        return;
      }

      this.visiblePartners.forEach((p) => {
        p.showTerms = false;
      });

      partner.showTerms = true;
    },
    selectPartner(partner) {
      this.TOGGLE_SELECTED_PARTNER(partner);

      setTimeout(() => {
        partner.showTerms = false;
      }, 0);
    },
    openLink(link) {
      this.link = link;
    },
    scrollToPartner(index) {
      if (index < 0 || index >= this.totalDots) return;

      this.currentIndex = index;
      const partnersElement = this.$refs.partners;
      const scrollAmount = (partnersElement.scrollWidth / this.totalDots) * index;
      partnersElement.scrollTo({ left: scrollAmount, behavior: "smooth" });
    },
    handleScroll() {
      const partnersElement = this.$refs.partners;
      const totalWidth = partnersElement.scrollWidth - partnersElement.clientWidth;
      const scrollFraction = partnersElement.scrollLeft / totalWidth;

      this.currentIndex = Math.round(scrollFraction * (this.totalDots - 1));
    },
    submit() {
      if (this.getSelectedPartners.length === 0) {
        return;
      }
      this.$router.push("/register-done");
    },
  },
  created() {
    this.$store.dispatch("loadPartners");
  },
  mounted() {
    const partnersElement = this.$refs.partners;
    if (partnersElement) {
      partnersElement.addEventListener("scroll", this.handleScroll);
    }
  },
  beforeUnmount() {
    const partnersElement = this.$refs.partners;
    if (partnersElement) {
      partnersElement.removeEventListener("scroll", this.handleScroll);
    }
  },
};
</script>

<style scoped>
.partners {
  display: flex;
  justify-content: center;
  gap: 1vw;
  height: 20vw;
  margin-top: 3vw;
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 3vw;
}

.partner {
  width: 13vw;
  height: 9vw;
  background: rgba(217, 217, 217, 0.3);
  border-radius: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: visible;
}

.partner-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.partner-box img {
  max-width: 7.5vw;
  max-height: 7.5vw;
  object-fit: contain;
  z-index: -1;
}

.partner-terms {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #68aef9;
  color: #fff;
  font-family: "Roboto Bold";
  font-size: 1vw;
  letter-spacing: -0.05vw;
  padding: 0.75vw 1vw;
  border-radius: 3vw;
  width: 20vw;
  height: auto;
  text-align: center;
  line-height: 1.5;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  z-index: 99;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: auto;
}

.partner-terms::before {
  content: "";
  position: absolute;
  top: -0.5vw;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0.5vw;
  border-style: solid;
  border-color: transparent transparent #68aef9 transparent;
}

.overflow-dots {
  display: flex;
  justify-content: center;
  margin-top: 1vw;
}

.dot {
  width: 2vw;
  height: 2vw;
  border-radius: 50%;
  margin: 0 0.25vw;
  box-shadow: 0 5px 5px 0 #44444425;
  cursor: pointer;
}

.partner-selected {
  background: #68aef9;
  z-index: 1;
}

.btn {
  background-color: #68aef9;
  color: #fff;
  font-family: "Roboto Bold";
  font-size: 1.5vw;
  letter-spacing: -0.075vw;
  padding: 0.5vw 1vw;
  border-radius: 3vw;
  width: 13vw;
  height: 3vw;
  border: none;
  cursor: pointer;
  text-align: center;
  line-height: 3vw;
  box-shadow: 0 5px 5px 0 rgba(45, 45, 45, 0.15);
  margin: auto;
  margin-top: 2vh;
  transition: transform 0.2s;
}


.btn:hover {
  transform: scale(1.05);
}

.btn:focus {
  outline: none;
}
</style>
