<template>
    <div class="iframe-overlay">
      <div class="iframe-container">
        <button class="close-btn" @click="hideIframe">X</button>
        <iframe :src="link" frameborder="0" width="100%" height="100%"></iframe>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: true,
    },
  },
  methods: {
    hideIframe() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>

.iframe-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.iframe-container {
  position: relative;
  width: 80%;
  height: 80%;
  padding: 2vw;
}

.close-btn {
  position: absolute;
  top: 1vw;
  right: 1vw;
  background-color: #ff0000;
  color: white;
  font-size: 1.2vw;
  border: none;
  padding: 0.5vw;
  cursor: pointer;
}

.close-btn:hover {
  background-color: #cc0000;
}
</style>