import { createRouter, createWebHashHistory } from "vue-router";

import WelcomePage from "../views/WelcomePage.vue";
import GenderPage from "../views/GenderPage.vue";
import EnterPhonePage from "../views/phone/EnterPhonePage.vue";
import VerifyPhonePage from "../views/phone/VerifyPhonePage.vue";
import IdCheckPage from "../views/idcheck/IdCheckPage.vue";
import CheckDataPage from "../views/idcheck/CheckDataPage.vue";
import SelectJobPage from "../views/SelectJobPage.vue";
import EnterEmailPage from "../views/email/EnterEmailPage.vue";
import TermsAndConditionsPage from "../views/TermsAndConditionsPage.vue";
import RegisterPage from "../views/RegisterPage.vue";
import RegisterDonePage from "../views/RegisterDonePage.vue";
import VerifyEmailPage from "../views/email/VerifyEmailPage.vue";
import CheckAddressPage from "../views/idcheck/CheckAddressPage.vue";
import FaceRecognition from "../views/idcheck/face/FaceRecognition.vue";
import FaceRecognitionFailed from "../views/idcheck/face/FaceRecognitionFailed.vue";
import FaceRecognitionSuccess from "../views/idcheck/face/FaceRecognitionSuccess.vue";

const routes = [
  { path: "/", component: WelcomePage },
  { path: "/gender", component: GenderPage },
  { path: "/phone", component: EnterPhonePage },
  { path: "/phone/verify", component: VerifyPhonePage },
  { path: "/email", component: EnterEmailPage },
  { path: "/email/verify", component: VerifyEmailPage },
  { path: "/id-check", component: IdCheckPage },
  { path: "/id-check/face", component: FaceRecognition },
  { path: "/id-check/face/success", component: FaceRecognitionSuccess },
  { path: "/id-check/face/failed", component: FaceRecognitionFailed },
  { path: "/id-check/confirmation", component: CheckDataPage },
  { path: "/id-check/address/confirmation", component: CheckAddressPage },
  { path: "/select-job", component: SelectJobPage },
  { path: "/terms-and-conditions", component: TermsAndConditionsPage },
  { path: "/register-where", component: RegisterPage },
  { path: "/register-done", component: RegisterDonePage },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
