<template>
  <div class="partner-list">
    <div class="partner-tag">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="23"
        fill="none"
        viewBox="0 0 26 23"
      >
        <path
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2.7"
          d="M9.25 6.833a3.625 3.625 0 0 0 7.25 0M2 17.467v-11.6c0-1.354 0-2.03.263-2.548A2.415 2.415 0 0 1 3.32 2.263C3.836 2 4.513 2 5.867 2h14.017c1.353 0 2.029 0 2.546.263.454.232.825.602 1.057 1.056.263.517.263 1.193.263 2.544V17.47c0 1.35 0 2.026-.263 2.543a2.42 2.42 0 0 1-1.057 1.056c-.517.263-1.192.263-2.543.263H5.863c-1.35 0-2.027 0-2.544-.263a2.417 2.417 0 0 1-1.056-1.056C2 19.497 2 18.82 2 17.467Z"
        />
      </svg>
      <p class="partner-text">{{ $t("partner.title") }}</p>
    </div>
    <div class="carousel-wrapper">
      <div class="carousel">
        <div class="partner" v-for="partner in partners" :key="partner.name">
          <div class="partner-box">
            <img :src="partner.image" :alt="partner.name" />
          </div>
          <p class="partner-name">{{ partner.name }}</p>
          <p class="partner-type">{{ partner.type }}</p>
        </div>
        <div class="partner" v-for="partner in partners" :key="'clone-' + partner.name">
          <div class="partner-box">
            <img :src="partner.image" :alt="partner.name" />
          </div>
          <p class="partner-name">{{ partner.name }}</p>
          <p class="partner-type">{{ partner.type }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PartnerComponent",
  computed: {
    ...mapGetters(["partners"]),
  },
};
</script>

<style scoped>
.partner-list {
  display: block;
  margin: 2vw 0 0 2vw;
  border-radius: 1.5vw;
  width: 10vw;
  height: 90%;
  background-color: rgba(217, 217, 217, 0.5);
  overflow: hidden;
  margin-left: -2vw;
}

.partner-tag {
  background-color: #68aef9;
  border-top-left-radius: 1.5vw;
  border-top-right-radius: 1.5vw;
  width: 100%;
  display: flex;
  height: 8.75vh;
  flex-wrap: wrap;
}

.partner-tag svg {
  height: 1.25vw;
  width: 100%;
  margin-top: 1.5vh;
}

.partner-text {
  font-family: "Roboto Bold";
  font-size: 0.9vw;
  color: #fff;
  text-align: center;
  width: 100%;
  letter-spacing: -0.075vw;
  padding-bottom: 0.5vw;
}

.carousel-wrapper {
  height: 31vw;
  position: relative;
  overflow: hidden;
}

.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: scroll 120s linear infinite;
}

.partner {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin-bottom: 1vw;
}

.partner .partner-box {
  display: flex;
  justify-content: center;
}

.partner .partner-box img {
  width: 2.5vw;
  margin: auto;
}

.partner .partner-box {
  background: #d9d9d9;
  height: 4vw;
  width: 4vw;
  border-radius: 1.5vw;
}

.partner-name {
  color: #fff;
  font-family: "Roboto Bold";
  font-size: 1.1vw;
  letter-spacing: -0.075vw;
  margin: 0;
  width: 4vw;
}

.partner-type {
  color: #7a7a7a;
  font-family: "Roboto Bold";
  font-size: 0.75vw;
  letter-spacing: -0.075vw;
  margin: 0;
  width: 4vw;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-85%);
  }
}
</style>
